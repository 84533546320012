/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import { Helmet } from "react-helmet"
 import { useStaticQuery, graphql } from "gatsby"

 function SEO({ description, lang, meta, title }) {
   const { site } = useStaticQuery(
     graphql`
       query {
         site {
           siteMetadata {
             title
             description
             keywords
           }
         }
       }
     `
   )

   const metaDescription = description || site.siteMetadata.description
   const metaTitle = title || site.siteMetadata?.title
   const keywords = site.siteMetadata?.keywords

   return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      // titleTemplate={'%s - RAPNETBUY（宝猫科技）｜一站式B2B钻石交易服务平台'}
      titleTemplate={'%s'}
      link={[
        {
          rel: 'icon',
          href: 'https://wanbaorapdia.oss-cn-shenzhen.aliyuncs.com/fileserver/image/rapnetbuy/favicon-web.png',
          type: 'image/x-icon'
        }
      ]}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: keywords,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: 'https://wanbaorapdia.oss-cn-shenzhen.aliyuncs.com/fileserver/image/rapnetbuy/favicon-web.png',
        },
      ].concat(meta)}
    >
      <script type="text/javascript">{
        `
          var _hmt = _hmt || [];
          (function() {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?${process.env.GATSBY_BAIDU_ANALYTIC_KEY}";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
          })();
        `
      }</script>
    </Helmet>
   )
 }

 SEO.defaultProps = {
   lang: 'zh-CN',
   meta: [],
   description: '',
   title: ''
 }

 SEO.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string,
 }

 export default SEO